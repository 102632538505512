<template>
  <v-container fluid>
   <v-form ref="form" @submit.prevent="validateBeforeSubmit">


   <v-col cols="12">
          <v-radio-group
            v-model="vm.unauthorisedUseOfRestrictivePractice"
            label="Has unauthorised use of a restrictive practice occurred?"
            v-validate="'required'"
            data-vv-as="Has unauthorised use of a restrictive practice occurred"
            name="unauthorisedUseOfRestrictivePractice"
            :error-messages="errors.collect('unauthorisedUseOfRestrictivePractice')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>


     <template v-if="vm.unauthorisedUseOfRestrictivePractice == true ">

        <v-col cols="12">
          <v-select
            v-model="vm.restrictivePracticeType"
            :items="restrictivePracticeType"
            label="Restrictive Practice Type"
            v-validate="'required'"
            data-vv-as="Restrictive Practice Type"
            name="restrictivePracticeType"
            :error-messages="errors.collect('restrictivePracticeType')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.doesPersonHaveCurrentNDISBSP"
            label="Does the person have a current NDIS behaviour support plan?"
            v-validate="'required'"
            data-vv-as="Does the person have a current NDIS behaviour support plan"
            name="doesPersonHaveCurrentNDISBSP"
            :error-messages="errors.collect('doesPersonHaveCurrentNDISBSP')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
     
     
     
     
        <v-col cols="12" v-if="vm.doesPersonHaveCurrentNDISBSP == true">
          <v-text-field
            v-model="vm.bspNumber"
            label="What is the behaviour support plan ID number"
            v-validate="'required'"
            data-vv-as="BSP ID number"
            name="bspNumber"
            :error-messages="errors.collect('bspNumber')"
          ></v-text-field>
        </v-col>    

      <v-col cols="12">
        <v-select
          v-model="vm.behavioursOfConcern"
          :items="behavioursOfConcern"
          multiple
          label="Behaviours of Concern"
          v-validate="'required'"
          data-vv-as="Behaviours of Concern"
          name="behavioursOfConcern"
          :error-messages="errors.collect('behavioursOfConcern')"
        ></v-select>
      </v-col>

      <v-col cols="12" md="4" v-if="vm.behavioursOfConcern == 'Other'">
        <v-text-field
          v-model="vm.behavioursOfConcernOther"
          label="please specify other behaviours of concern"
          v-validate="'required'"
          data-vv-as="please specify other behaviours of concern"
          name="behavioursOfConcernOther"
          :error-messages="errors.collect('behavioursOfConcernOther')"
        ></v-text-field>
      </v-col>

 <v-col cols="12">
          <v-radio-group
            v-model="vm.isOneOffEmergency"
            label="Is this a one-off emergency use that is unlikely to recur?"
            v-validate="'required'"
            data-vv-as="Is this a one-off emergency use that is unlikely to recur"
            name="isOneOffEmergency"
            :error-messages="errors.collect('isOneOffEmergency')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Unsure" value="unsure"></v-radio>
          </v-radio-group>
        </v-col>

   <v-col cols="12">
          <v-radio-group
            v-model="vm.hasSoughtAuthorisationUseOfRestrictivePractice"
            label="Have you sought state/territory authorisation and consent to use the restrictive practice?"
            v-validate="'required'"
            data-vv-as="authorisation and consent to use the restrictive practice"
            name="hasSoughtAuthorisationUseOfRestrictivePractice"
            :error-messages="errors.collect('hasSoughtAuthorisationUseOfRestrictivePractice')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>

 <v-col cols="12">
          <v-radio-group
            v-model="vm.hasSoughtAmendmentToExistingBSP"
            label="Have you sought an amendment to the existing behaviour support plan?"
            v-validate="'required'"
            data-vv-as="Have you sought an amendment to the existing behaviour support plan"
            name="hasSoughtAmendmentToExistingBSP"
            :error-messages="errors.collect('hasSoughtAmendmentToExistingBSP')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

 <v-col cols="12">
          <v-radio-group
            v-model="vm.hasInitiatedBehaviourSupportAssessmentPlan"
            label="Have you initiated an NDIS behaviour support assessment and plan?"
            v-validate="'required'"
            data-vv-as="Have you initiated an NDIS behaviour support assessment and plan"
            name="hasInitiatedBehaviourSupportAssessmentPlan"
            :error-messages="errors.collect('hasInitiatedBehaviourSupportAssessmentPlan')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>




 </template>

        <v-btn color="primary" type="submit">Continue</v-btn>
      
  

    
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "incident-category",

  data: () => ({
    vm: [],
    restrictivePracticeType: [
      "Mechanical restraint",
      "Physical restraint",
      "Seclusion",
      "Environmental restraint",
      "Chemical restraint"
    ],
        behavioursOfConcern: [
      "Food-related",
      "Eating non-food items",
      "Propert damage",
      "Physical aggresion",
      "Verbal aggresion",
      "Harm to self",
      "Unintentional self-risk",
      "Leaving premises without support",
      "Refusal to do things",
      "Repetitive or unusual habits",
      "Offending behaviour",
      "Sexually inappropriate behaviour",
      "Other"
    ],
    date: new Date().toISOString().substr(0, 10),
    incidentTimeMenu: false,
    incidentTimeNDISAwareMenu: false,
    incidentFirstWorkerAwareTimeMenu: false,
    time: null,
    menu2: false,
    menu3: false,
    menu4: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.unauthorisedRestrictivePractice == undefined
          ? {}
          : this.currentIncident.unauthorisedRestrictivePractice;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.unauthorisedRestrictivePractice = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

